.carousel .carousel-slider{
    height: 300px;
}
.carousel .slide img{
    /* border-radius: 0; */
}
.carouselImageDiv .carouselImg{
    width: 100%;
    height: 300px;
    object-fit: fill;
}
.thumbs{
    display: flex;
    align-items: center;
    justify-content: center;
}