.BusinessNameRE{
    color:#202020 !important;
}
.BusinessNameRE1{
    width: 70%;
    /* float: left; */
}

.BusinessNameRE2{
    width: 30%;
    float: left;
    margin-top: 15%;
}

.BusinessNameSubRE1{
    width: 15%;
    float: left;
}

.BusinessNameSubRE2{
    width: 85%;
    float: left;
}

.carosel-border-RE img{
    width:90% !important;
    height:200px;
    border-radius:10px;
    margin:5%;
    margin-left:0;
}

.re_bus_sub{
    color:rgba(0, 0, 0, 0.6)!important;
    margin-top: 30px;
    font-size: 12px;
    font-weight: 500;
    margin-top: -10px;
}

.re_price{
    color:#FF5C00!important;
    font-size: 16px;
    font-weight: 700;
    font-family: 'EudoxusSans-Bold';
}

.BusinessNameSubRE1 img{
    width:30px;
    border-radius: 30px;
    margin-top:-15px;
}

.cardmobileRE{
    min-height: 200px;
    border-radius: 15px;
    box-shadow: #202020;
    border: 1px solid #ccc;
    width: 350px;
    margin-left: 10px;
}


.MuiDrawer-paperAnchorBottom {
    background: transparent !important;
}

.input_text2 .react-tel-input .form-control {
    border: 1.5px solid #EAEAEA !important;
    border-radius: 15px !important;
    height: 54px !important;
}

.businessname{
    font-size:12px;
}

.businessname p{
    font-size:12px;
    margin-top:0px;
}

.nameprice h2{
    font-size:20px;
}

.nameprice p{
    color:#FF5C00!important;
    font-size: 16px;
    font-weight: 700;
    font-family: 'EudoxusSans-Bold';
}

.feature p{
    font-size:14px;
    font-weight:500;
}

.feature span{
    font-size:12px
}

.slideInner___2mfX9{
    padding-right:18px;
}

.slideInner___2mfX9 img{
    border-radius: 15px;
}

.BusinessNameRE3 Label{
    font-size: 13px;
    font-weight: 400;
}

.react-tel-input .selected-flag {
    /* width: 68px !important; */
    padding: 0px 0 0 12px !important;
}

 .react-tel-input .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -10px;
    left: 20px;
    width: 0;
    height: 0;
    padding-left: 2px;
    border-left:0px solid transparent !important;
    border-right: 0px solid transparent !important;
    border-top: 0px solid #fff !important;
}

/* .react-tel-input .selected-flag .arrow:before {
    content: "+91";
  } */

  .react-tel-input .selected-flag .arrow.up {
    border-top: none;
    border-bottom: 0px solid #fff !important;
}
.country-list li{
    display: none;
}

/* .react-tel-input .form-control {
    position: relative;
letter-spacing: .03rem !important;
} */

.ui.label {
    margin-bottom: 10px !important;
}