.spinner {
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.spinner img {
  width: 10rem;
}