.App {
  text-align: center;
}
@font-face {
  font-family: 'EudoxusSans-Bold';
  src: local('EudoxusSans'), url(/static/media/EudoxusSans-Bold.b1a09c4d.woff) format('woff');
}

@font-face {
  font-family: 'EudoxusSans-Regular';
  src: local('EudoxusSans'), url(/static/media/EudoxusSans-Regular.ec03f48c.woff) format('woff');
}

body{
  touch-action: manipulation;
  font-family: 'EudoxusSans-Regular' !important;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 30px;
  font-size: 16px;
  margin-top: 40px;
}

.carosel-border{
 border-radius: 10px;

}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.legend{
  color:#fff !important;
  background-color: #0F6EFF !important;
  opacity: 1 !important;
  font-size: 16px !important;
}

.prod_desc{
  /* background-image: url('../src/asset/images/2-nd-section-bg.png');
  background-repeat: no-repeat;
  background-size: 99% 133%; */
  background-color: #FFF;
  padding: 20px;
  border-radius: 15px;
}

.primary-btn2{
  background-color: #FFA800 !important;
  color:#fff !important;
  border: 1px solid #FFA800 !important;
  width: 320px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
 font-size: 16px;
 /* margin-top: 10px; */
 margin-bottom: 10px;
 position: fixed;
}

.primary-btn5{
  background-color: #017AC3 !important;
  color:#fff !important;
  border: 1px solid #017AC3 !important;
  width: 320px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
 font-size: 16px;
 /* margin-top: 10px; */
 margin-bottom: 10px;
 position: fixed;
}

.primary-btn {
  background-color: #FFA800 !important;
  color: #fff !important;
  border: 1px solid #FFA800!important;
  width: 100%;
  height: 60px;
  font-weight: 700;
  font-size: 20px;
  position: fixed;
   left: 0;
   bottom: 0;
  z-index: 6;
}

.primary-btn4 {
  background-color: #017AC3 !important;
  color: #FFF !important;
  border: 1px solid #017AC3!important;
  width: 100%;
  height: 60px;
  font-weight: 700;
  font-size: 20px;
  position: fixed;
   left: 0;
   bottom: 0;
  z-index: 6;
}

.primary-btn3 {
  background-color:#FF5C00 !important;
  color: #fff !important;
  border: 1px solid #FF5C00!important;
  width: 100%;
  height: 60px;
  font-weight: 700;
  font-size: 20px;
  border-radius: 10px;
}

.primary-btn1{
  background-color: #FFA800 !important;
  color:#fff !important;
  border: 1px solid #FFA800 !important;
  width: 300px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
 font-size: 16px;
 margin-left: 5%;
 
}
.secondary-btn{
  background-color: #6c757d !important;
  color:#fff !important;
  width: 320px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
 font-size: 16px;
 bottom: 0;
}

.section-3{
  background-image: url(/static/media/bg-blue.6f80eba1.svg);
  background-repeat: no-repeat;
  padding: 10px;
  background-size: 100% 120%;
}

.section-3 img{
  width: 68px;
}

.padding-text{
  padding: 10px;
}

.section-3 h2, .section-3 p{
  color:#fff;
}

.section-3 p{
  color:#fff;
  font-size: 21px !important;
}

.section-3 h2{
  font-size: 55px !important;
  margin-top:10px;
}

.carsel-images-div{
    align-items: center;
    padding: 10px;
    
    
}

.carousel .slide img {
  width: 100%;
  border: 0;
  border-radius: 15px;
}

.container h2 {
  margin-bottom: 5px;
  font-size: 32px;
  text-align: center;
  font-style: normal;
  line-height: 41px;
}

.Desc{
  margin-top:-10%;
}

.bottom-stick{

  position:absolute;
left:0;
bottom:0;
right:0;
width: 100% !important;

}

.success-div{
  padding: 16%;
  text-align: center;
}

p{
  color:#919191;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.carousel .slide .testimonial-avatar {
  width: 100px;
  height: 100px;
  text-align:left ;
}

.carousel-status{
  display: none;
}

.test-main-div{
  background-color: #FFF;
  padding-bottom: 30px;
}

.col-30{
  width:20%;
  float: left;
  text-align: center;
  padding-top: 3%;
  padding-left: 10px;
}

.col-70{
  width:70%;
  float: left;
  text-align: left;
  padding-left: 15px;
}

.col-70 p{
  margin-top: -15px !important;
}

.col-100{
  clear: both;
  width:100%;
  text-align: center;
  margin-left: 10%;
}
/* .BusinessName{
  background-image: url('./asset/images/doodle.svg');
  background-repeat: no-repeat;
  background-size: 92% 193%;
} */

.businessNameh2{
margin-top: 0px;
font-size: 38px !important;
text-transform: capitalize;
margin-bottom: 0px;
}

.text-font{
  font-size:16px;
  line-height: 26px;
  color:#000000;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-label{
  font-size: 13px;
  color:#000000;
  margin-top: -6px;
    position: absolute;
    margin-left: 6px;
    background: #fff;
    z-index: 4;
}

.react-tel-input .form-control {
  border: 1.5px solid #EAEAEA !important;
  border-radius: 15px !important;
height: 54px !important;
width:320px !important
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: repeat !important;
}

.react-tel-input .form-control:focus-visible {
  outline : 2px solid #9c27b0 ;
}

.react-tel-input .flag-dropdown {
 background-color: #fff !important;
 border: 1.5px solid #EAEAEA !important;
 border-radius: 15px 0px 0px 15px !important;
}

.carousel .control-dots .dot{
  background: #000000 !important;
}

.formH2{
  font-size: 32px;
  margin-top: -6px;
  z-index: 1;
  font-weight: 700;
}

.otpBox{
  width:50px !important;
  height:50px;
  margin: 10px;
  border:1.5px solid #919191;
  border-radius:5px;
  font-size: 20px;
}

.otpFocus{
  color:#0F6EFF !important;
  box-shadow: 2px;
  border-color: 1.5px solid #0F6EFF;
}
.otpContain{
  margin: 20px;
}

/* hr {
  background: #FFBD00;
  width: 50%;
  color: #FFBD00;
  height: 4px;
  border-radius: 50px;
  margin-top: -38px;
} */

.cardBodyStyle{
/* background-color: #000000; */
border-radius: 15px;
}

.cardBodyStyle img{
  /* background-color: #000000; */
  height:300px;
  }

  .carousel.carousel-slider .control-arrow:hover {
    width: 30px !important;
    border-radius: 60% !important;
    height: 40px !important;
    margin-top: 31% !important;
}

.carousel.carousel-slider .control-arrow {
  width: 30px !important;
  border-radius: 60% !important;
  height: 40px !important;
  margin-top: 31% !important;
  background: rgba(255, 255, 255, 0.6) !important;
}

/* .country{
  display: none;
} */

.otp_div{
  margin-top: -20px;
}

.prod_desc ol {
    line-height: 28px;
    text-align:left;
    /* list-style-image: url('../src/asset/icons/tick3.svg'); */
    font-size: 18px;
    color: #6c757d;
    list-style-type: none;
    vertical-align: middle;
    margin-left: -25px !important;
  }

  .prod_desc ol p{

    color: #6c757d !important;
    font-size: 18px !important;
  }

  .prod_desc ol img{

    vertical-align: middle;
  }

  .prod_desc ol p:last-child {
    margin-bottom: 10px;
}
.prod_desc ol p:first-child {
    margin-top: 10px;
}


.icon-div{
  float: left;
  width:10%;
  margin-top: 10px;
}
.icon-text-div{
  float: left;
  /* padding-right: 10px; */
  width:90%;
}

.loader{
    align-items: center;
    margin-top: 70%;
    margin-left: 35%;
}

.footer_desc{
  padding-bottom:70px;
  background-color: #0F6EFF;
  color: #fff !important;
}

.footer_desc p{
  color: #fff !important;
  text-align: left !important;
  margin-left: 20px;
  margin-bottom: -3px;
}

.footer_desc a{
  color: #FFF;
  text-decoration: none;
}
.col-50{
  width:50%;
  float: left;
}
.copy-right{
  clear: both;
  padding-top: 25px;
  background-color: #0F6EFF ;
  color: #FFF;
  font-weight: 500;

}

.footer_desc ul li{
  list-style-type: none;
  text-align: left;
  text-decoration: none;
  padding:10px;
  color: #FFF;
}
#footer-cust-data{
 color: #FFF !important;
 font-weight: 700;
 font-size: 30px;
 text-align: left;
 margin-left: 20px;
 padding-top: 15px;
}

.follow_img{
  width: 20px;
  text-align: left;
  padding-right:10px;
}

.brand{
color:#FFF;
}

#followus{
  font-size: 20px !important;
  text-decoration: underline;
  text-align: left;
  margin-left:20px;
}

#cust_serv{
  font-size: 20px !important;
  text-decoration: underline;
  text-align: left;
  margin-left: 20px;

}

.followup_cls{
  padding-top: 0px;
}
.img-fluid{
  margin-top: 5px;
}
  .container h2{
    margin-bottom: 5px;
    text-align: center;
    font-family: 'EudoxusSans-Bold' !important;
    font-weight: 700 !important;


}
.container h3{
    font-weight: 400;
    font-size:1.3rem;
    text-align: center;
    margin-bottom: 10px;
    font-family: 'EudoxusSans-Regular';
    margin-top: 5px;
    color: rgb(0,0,0,0.5) !important;

}
  .slick-prev:before,
.slick-next:before {
  color: #007FFF !important;
  font-size: 30px !important;

  
}
  .slide-content{
    box-shadow: 6px 6px 10px #b2b2b2;
    z-index: 4;
    text-align: left;
    padding: 30px;
    width: 350px;
    margin: 10px auto;
    height: 350px;
    background: white;
    border-radius: 20px;
  }
  .test-image img{
    width: 80px;
    height: 80px;
    border-radius:50%;
  }
  .slide-content h3{
    margin: 20px 0 5px;
    font-size: 1.5em;
    text-align:left;
  }
  .slide-content h4{
    margin: 5px 0;
    font-weight: 500;
    font-size:1em;
    color:#888;
  }
  .slide-content p{
    margin:5px 0;
    font-size: 1.1em;
  }


  .explore{
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    color: #fff;
    background-color:#007FFF;
    padding: 10px 0;
    border-radius:15px;
    text-align: center;
    width: 250px;
   
    font-size: 15px;
}

@media only screen and (max-width: 500px) {
  .slide-content{
    box-shadow: none;
   
    padding: 10px;
    
  }
  .slide-content p{
  
   
    font-size: 0.9em;
    width: 100%;
    overflow-wrap: break-word;
  }
}

.card-img{
    width:100px !important;
    border-radius: 100px;
}

.input_text{
  box-sizing: border-box;
width: 350px !important;
left: 15px;
top: 468px;

background: #FFFFFF;
border: 1.5px solid #EAEAEA;
border-radius: 15px;
padding: 10px;
padding-top: 16px;
padding-bottom: 13px;
font-size: 1rem;
}

.car_card {
  border-radius: 6.6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 0 0.5rem ;
  position: relative;
}

.car_card_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.car_card_title {
  height: 7rem;
  width: 100%;
  margin-top: 0.5rem;
  padding:  0  0.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top, #000 , rgba(0,0,0,0));
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.6rem ;
  padding-bottom: 0.8rem;
}

.car_card_title> h4 {
  font-weight: 700;
  font-size: 1.2rem;
}

.car_card_title> h4, p {
  color: #fff;
  text-align: left;
}



.car_card_title> p {
 font-size: 1rem;
 font-size: 0.9rem;
}

.car_price {
  color: #FF9D29;
  color: #fff;
}

.car_card_detail {
display: flex;
align-items: center;
padding: 1rem 0.5rem;

}

.car_card_detail>*:not(:last-child) {
  margin-right: 0.3rem;
}

.car_card_stat {
  font-size: 0.9rem;
} 

.car_card_chip {
  /* background-color:#E65100; */
  color: #fff ;
  border-radius: 1rem;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.MuiPopover-root {
  z-index: 10000000 !important;
}

.MuiOutlinedInput-root {
  border-radius: 0.9rem !important;
  /* outline: 1.5px solid green !important; */
  background-color: #fff !important;

}

.MuiSelect-outlined.MuiSelect-outlined {
  background-color: #fff !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: #000;
}



.BusinessNameRE{
    color:#202020 !important;
}
.BusinessNameRE1{
    width: 70%;
    /* float: left; */
}

.BusinessNameRE2{
    width: 30%;
    float: left;
    margin-top: 15%;
}

.BusinessNameSubRE1{
    width: 15%;
    float: left;
}

.BusinessNameSubRE2{
    width: 85%;
    float: left;
}

.carosel-border-RE img{
    width:90% !important;
    height:200px;
    border-radius:10px;
    margin:5%;
    margin-left:0;
}

.re_bus_sub{
    color:rgba(0, 0, 0, 0.6)!important;
    margin-top: 30px;
    font-size: 12px;
    font-weight: 500;
    margin-top: -10px;
}

.re_price{
    color:#FF5C00!important;
    font-size: 16px;
    font-weight: 700;
    font-family: 'EudoxusSans-Bold';
}

.BusinessNameSubRE1 img{
    width:30px;
    border-radius: 30px;
    margin-top:-15px;
}

.cardmobileRE{
    min-height: 200px;
    border-radius: 15px;
    box-shadow: #202020;
    border: 1px solid #ccc;
    width: 350px;
    margin-left: 10px;
}


.MuiDrawer-paperAnchorBottom {
    background: transparent !important;
}

.input_text2 .react-tel-input .form-control {
    border: 1.5px solid #EAEAEA !important;
    border-radius: 15px !important;
    height: 54px !important;
}

.businessname{
    font-size:12px;
}

.businessname p{
    font-size:12px;
    margin-top:0px;
}

.nameprice h2{
    font-size:20px;
}

.nameprice p{
    color:#FF5C00!important;
    font-size: 16px;
    font-weight: 700;
    font-family: 'EudoxusSans-Bold';
}

.feature p{
    font-size:14px;
    font-weight:500;
}

.feature span{
    font-size:12px
}

.slideInner___2mfX9{
    padding-right:18px;
}

.slideInner___2mfX9 img{
    border-radius: 15px;
}

.BusinessNameRE3 Label{
    font-size: 13px;
    font-weight: 400;
}

.react-tel-input .selected-flag {
    /* width: 68px !important; */
    padding: 0px 0 0 12px !important;
}

 .react-tel-input .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -10px;
    left: 20px;
    width: 0;
    height: 0;
    padding-left: 2px;
    border-left:0px solid transparent !important;
    border-right: 0px solid transparent !important;
    border-top: 0px solid #fff !important;
}

/* .react-tel-input .selected-flag .arrow:before {
    content: "+91";
  } */

  .react-tel-input .selected-flag .arrow.up {
    border-top: none;
    border-bottom: 0px solid #fff !important;
}
.country-list li{
    display: none;
}

/* .react-tel-input .form-control {
    position: relative;
letter-spacing: .03rem !important;
} */

.ui.label {
    margin-bottom: 10px !important;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error {
  color: red;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  bottom: 0;
}

.formError {
  color: red;
  margin: 0;
  text-align: center;
  font-size: 13px;
}

.otpsent{
  color: green;
  text-align: center;
  margin: 0;
  font-size: 13px;
}

.content-lists{
  list-style: none;
  padding: 0;
  width: 100%;
}

.content-list{
  display: flex;
  align-items: center;
}
.carousel .carousel-slider{
    height: 300px;
}
.carousel .slide img{
    /* border-radius: 0; */
}
.carouselImageDiv .carouselImg{
    width: 100%;
    height: 300px;
    object-fit: fill;
}
.thumbs{
    display: flex;
    align-items: center;
    justify-content: center;
}
.spinner {
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.spinner img {
  width: 10rem;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

