input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error {
  color: red;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  bottom: 0;
}

.formError {
  color: red;
  margin: 0;
  text-align: center;
  font-size: 13px;
}

.otpsent{
  color: green;
  text-align: center;
  margin: 0;
  font-size: 13px;
}

.content-lists{
  list-style: none;
  padding: 0;
  width: 100%;
}

.content-list{
  display: flex;
  align-items: center;
}