  .container h2{
    margin-bottom: 5px;
    text-align: center;
    font-family: 'EudoxusSans-Bold' !important;
    font-weight: 700 !important;


}
.container h3{
    font-weight: 400;
    font-size:1.3rem;
    text-align: center;
    margin-bottom: 10px;
    font-family: 'EudoxusSans-Regular';
    margin-top: 5px;
    color: rgb(0,0,0,0.5) !important;

}
  .slick-prev:before,
.slick-next:before {
  color: #007FFF !important;
  font-size: 30px !important;

  
}
  .slide-content{
    box-shadow: 6px 6px 10px #b2b2b2;
    z-index: 4;
    text-align: left;
    padding: 30px;
    width: 350px;
    margin: 10px auto;
    height: 350px;
    background: white;
    border-radius: 20px;
  }
  .test-image img{
    width: 80px;
    height: 80px;
    border-radius:50%;
  }
  .slide-content h3{
    margin: 20px 0 5px;
    font-size: 1.5em;
    text-align:left;
  }
  .slide-content h4{
    margin: 5px 0;
    font-weight: 500;
    font-size:1em;
    color:#888;
  }
  .slide-content p{
    margin:5px 0;
    font-size: 1.1em;
  }


  .explore{
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    color: #fff;
    background-color:#007FFF;
    padding: 10px 0;
    border-radius:15px;
    text-align: center;
    width: 250px;
   
    font-size: 15px;
}

@media only screen and (max-width: 500px) {
  .slide-content{
    box-shadow: none;
   
    padding: 10px;
    
  }
  .slide-content p{
  
   
    font-size: 0.9em;
    width: 100%;
    overflow-wrap: break-word;
  }
}

.card-img{
    width:100px !important;
    border-radius: 100px;
}

.input_text{
  box-sizing: border-box;
width: 350px !important;
left: 15px;
top: 468px;

background: #FFFFFF;
border: 1.5px solid #EAEAEA;
border-radius: 15px;
padding: 10px;
padding-top: 16px;
padding-bottom: 13px;
font-size: 1rem;
}

.car_card {
  border-radius: 6.6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 0 0.5rem ;
  position: relative;
}

.car_card_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.car_card_title {
  height: 7rem;
  width: 100%;
  margin-top: 0.5rem;
  padding:  0  0.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top, #000 , rgba(0,0,0,0));
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.6rem ;
  padding-bottom: 0.8rem;
}

.car_card_title> h4 {
  font-weight: 700;
  font-size: 1.2rem;
}

.car_card_title> h4, p {
  color: #fff;
  text-align: left;
}



.car_card_title> p {
 font-size: 1rem;
 font-size: 0.9rem;
}

.car_price {
  color: #FF9D29;
  color: #fff;
}

.car_card_detail {
display: flex;
align-items: center;
padding: 1rem 0.5rem;

}

.car_card_detail>*:not(:last-child) {
  margin-right: 0.3rem;
}

.car_card_stat {
  font-size: 0.9rem;
} 

.car_card_chip {
  /* background-color:#E65100; */
  color: #fff ;
  border-radius: 1rem;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.MuiPopover-root {
  z-index: 10000000 !important;
}

.MuiOutlinedInput-root {
  border-radius: 0.9rem !important;
  /* outline: 1.5px solid green !important; */
  background-color: #fff !important;

}

.MuiSelect-outlined.MuiSelect-outlined {
  background-color: #fff !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: #000;
}


